import React from 'react';
import AnchorLink from "../../AnchorLink";
import finova4 from "../../../../static/mockImages/finova-4.jpg";
import { H1 } from "../../Styles/Typography";
import { ButtonOutlineWhite, ButtonRightWrapper } from "../../Styles/Buttons";
import { Link } from "gatsby";
import styled from "styled-components";

const Funding = React.memo(({ fundingContent }) => {
  return (
    <FundingWrapper className="container-fluid">
      <AnchorLink id="funding" />
      <BgImg style={{ backgroundImage: `url(${finova4})` }} />
      <div className="container">
        <div className="row middle-md">
          <div className="col-xs-12 col-md-8">
            <H1>{ fundingContent.title }</H1>
            <p dangerouslySetInnerHTML={{ __html: fundingContent.paragraph }} />
          </div>
          <ButtonRightWrapper className="col-xs-12 col-md-4">
            <ButtonOutlineWhite as={Link} to={'/apply-for-funding'}>Apply for Funding</ButtonOutlineWhite>
          </ButtonRightWrapper>
        </div>
      </div>
    </FundingWrapper>
  )
});

const BgImg = styled.div`
  position: absolute;
  z-index: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0.45;
  top: 0;
  left: 0;
`;

const FundingWrapper = styled.section`
  padding: 2rem 0;
  background-color: ${props => props.theme.main};
  color: white;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;

  .container {
    z-index: 1;
    position: relative;
  }

  h1 {
    font-size: 26px;
    font-weight: bold;
  }

  p {
    text-align: justify;
    
    
    strong {
      font-weight: bold;
    }
  }
  
  @media (min-width: ${props => props.theme.md}) {
    padding: 4rem 0;

    h1 {
      font-size: 46px;
    }
    
    p {
    font-size: 20px;
    line-height: 1.6;
    }
  }
`;

export default Funding;