import React from "react";
import AnchorLink from "../../AnchorLink";
import { H3 } from "../../Styles/Typography";
import Partners from "./Partners";
import styled from "styled-components";

const PartnersSection = ({ partners, partnersTitle }) => {
  return (
    <PartnersSectionWrapper className="container-fluid">
      <AnchorLink id="partners" />
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <H3>{ partnersTitle.title }</H3>
            <Partners partners={partners} />
            {/*<H3>Portfolio</H3>*/}
            {/*<Portfolio />*/}
          </div>
        </div>
      </div>
    </PartnersSectionWrapper>
  );
};

const PartnersSectionWrapper = styled.section`
  margin: 2rem auto;
  text-align: center;

  @media (min-width: ${props => props.theme.md}) {
    margin: 4rem auto;
  }

  @media (min-width: ${props => props.theme.lg}) {
    margin: 7rem auto 1rem;
  }
`;

export default PartnersSection;
