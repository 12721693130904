import React, { useState } from "react";
import AnchorLink from "../../AnchorLink";
import { H3 } from "../../Styles/Typography";
import BannerSlider from "../Quote/BannerSlider";
import styled from "styled-components";
import { Button } from "../../Styles/Buttons";

const About = React.memo(({ aboutContent, bannerArticles }) => {
  const [isTextVisible, setTextVisibility] = useState(false);

  return (
    <AboutWrapper className="container">
      <AnchorLink id="about" />
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <H3>{ aboutContent.title }</H3>
          <p dangerouslySetInnerHTML={{ __html: aboutContent.paragraph }} />
          <AboutList>
            {
              aboutContent.list.map((list, index) => (
                  <li key={ index } className={ isTextVisible && index > 0 ? 'isVisible' : ''}>
                    <AboutListIcon>
                      <svg viewBox="0 0 82 82">
                        <g>
                          <path
                            d="M77 42.2A36.14 36.14 0 0 1 41 77C20.9 77 5.2 60 5 41 4.9 24.4 16.7 9.3 33.7 5c.2-1.5.3-3 .5-4.5-1.8.4-3.5.9-5.3 1.3A41.15 41.15 0 0 0 0 41c0 22.6 18.4 41 41 41 22.2 0 40.3-17.7 41-39.8h-5z"
                            className="st1" />
                          <path
                            d="M82 40.4l-.1-1.8h.1l-.3-3.2H43.5V5.1c14.5 1 26.6 10.6 31.3 23.7 1.5-.8 3-1.6 4.4-2.5A40.83 40.83 0 0 0 41 0c-.8 0-1.6 0-2.4.1l-.1-.1v72.9h5V40.4H82z"
                            className="st0" />
                        </g>
                      </svg>
                    </AboutListIcon>
                    <p dangerouslySetInnerHTML={{ __html: list.item }} />
                  </li>
                ))
            }
          </AboutList>
          <AboutButton as="button" onClick={() => setTextVisibility(!isTextVisible)}>
            { isTextVisible ? 'Read less' : 'Read more' }
          </AboutButton>
        </div>
        <div className="col-xs-12 col-md-6">
          <BannerSlider bannerArticles={ bannerArticles }/>
        </div>
      </div>
    </AboutWrapper>
  );
});

const AboutWrapper = styled.section`
  p {
    text-align: justify;
    
    
    strong {
      font-weight: bold;
      color: ${props => props.theme.main};
    }
  }
`;

const AboutButton = styled(Button)`
  margin-top: 2rem;
`;

const AboutList = styled.ul`
  margin-top: 1rem;
  
  li {
    position: relative;
    margin: 0.5rem auto 0.5rem 25px;
    display: none;
    
    &.isVisible {
      display: block;
    }
    
    &:first-of-type {
      display: block;
    }

    p {
      margin: 0;
    }
  }
`;

const AboutListIcon = styled.span`
  height: 15px;
  width: 15px;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: -25px;
  
  svg {
    height: 16px;
    width: 16px;

    .st0 {
      fill: ${props => props.theme.main};
    }

    .st1 {
      fill: ${props => props.theme.mainAccent};
    }
  }
`;

export default About;
