import React, { useState } from "react";
import TeamItem from "./TeamItem";
import { H4 } from "../../Styles/Typography";
import styled from "styled-components";
import { Icon } from "../../Icons/Icons";

const TeamList = React.memo(props => {
  const { teamMembers, children, openTeamMemberModal } = props;
  const [sliderIndex, setSliderIndex] = useState(
    teamMembers.length < 3 ? teamMembers.length - 1 : 2
  );

  const prev = () => {
    if (sliderIndex <= 2) return;
    return setSliderIndex(sliderIndex - 1);
  };

  const next = () => {
    if (sliderIndex === teamMembers.length - 1) return;
    return setSliderIndex(sliderIndex + 1);
  };

  return (
    <TeamSectionWrapper>
      <H4>{children}</H4>
      <TeamListWrapper>
        <Slider
          className={`row center-xs ${
            teamMembers.length < 3 ? "isReduced" : ""
          } ${teamMembers.length > 3 ? "isSlider" : ""}`}
          sliderIndex={sliderIndex}>
          {teamMembers.length > 0 &&
            teamMembers.map((item, index) => (
              <TeamItem
                teamMember={item}
                key={index}
                index={index}
                isSlider={teamMembers.length > 3}
                openTeamMemberModal={openTeamMemberModal}
                isReduced={teamMembers.length < 3}
              />
            ))}
        </Slider>
      </TeamListWrapper>
      {/* {teamMembers.length > 3 && (
        <SliderButtons
          sliderIndex={sliderIndex}
          teamLength={teamMembers.length}>
          <button onClick={() => prev()}>
            <Icon name="arrow-left" iconHeight={23} iconWidth={23} viewBoxHeight={509} viewBoxWidth={512}/>
          </button>
          <button onClick={() => next()}>
            <Icon name="arrow-right" iconHeight={23} iconWidth={23} viewBoxHeight={ 509 } viewBoxWidth={512}/>
          </button>
        </SliderButtons>
      )} */}
    </TeamSectionWrapper>
  );
});

const TeamSectionWrapper = styled.div`
  position: relative;
`;

const TeamListWrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

const Slider = styled.ul`
  justify-content: flex-start;

  @media (min-width: ${props => props.theme.lg}) {
    &.isSlider {
      justify-content: center;
      /* flex-wrap: nowrap;
      transition: transform 0.25s ease-out;
      will-change: transform;
      margin-bottom: 4rem;
      transform: ${props =>
        props.sliderIndex > 2
          ? `translateX( calc(-33.33% * ${ props.sliderIndex - 2 } - ${ props.sliderIndex - 2}rem) )`
          : `translateX(0)`}; */
    }
  }

  &.isReduced {
    @media (min-width: ${props => props.theme.lg}) {
      justify-content: center;
    }
  }
`;

const SliderButtons = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.lg}) {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 50px;
    right: -49px;
    border: 1px solid ${props => props.theme.main};
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 1px;
      background-color: ${props => props.theme.main};
      height: 100%;
      left: 50%;
    }

    button {
      text-align: center;
      cursor: pointer;
      outline: 0;
      color: ${props => props.theme.main};
      width: 50px;
      height: 50px;
      transition: background-color 0.25s ease-out;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .icon-wrapper {
        height: 23px;
      }
      
      &:hover {
        background-color: ${props => props.theme.main};
        color: white;
      }
    }

    button:first-of-type {
      pointer-events: ${props => (props.sliderIndex <= 2 ? "none" : "all")};
      opacity: ${props => (props.sliderIndex <= 2 ? "0.5" : "1")};
    }

    button:last-of-type {
      pointer-events: ${props =>
        props.sliderIndex === props.teamLength - 1 ? "none" : "all"};
      opacity: ${props =>
        props.sliderIndex === props.teamLength - 1 ? "0.5" : "1"};
    }
  }
`;

export default TeamList;
