import React from 'react';
import { H3 } from "../../Styles/Typography";
import styled from 'styled-components';
import InvestingForm from "../../Form/InvestingForm";
import AnchorLink from "../../AnchorLink";

const Investing = ({ investingContent}) => {
  return (
    <InvestingSection className="container-fluid">
      <AnchorLink id="invest" />
      <H3>{ investingContent.title }</H3>
      <InvestingForm />
    </InvestingSection>
  )
};

const InvestingSection = styled.section`
  padding: 4rem 0;
  background-color: ${ props => props.theme.mainLighter2 };

  h3 {
    text-align: center;
  }
`;



export default Investing;