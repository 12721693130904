import React, { useState } from "react";
import AnchorLink from "../../AnchorLink";
import { H3 } from "../../Styles/Typography";
import styled from "styled-components";
import TeamList from "./TeamList";
import TeamModal from "./TeamModal";

const Team = React.memo(({ teamContent, teamTitle }) => {
  let teamMembers = teamContent;
  let teamMemberIndex = 0;
  let members = [];

  teamMembers.map(section => {
    section.team_member.forEach((member, index) => {
      section.team_member[index] = {
        ...section.team_member[index],
        id: teamMemberIndex,
      };
      members.push(member);
      teamMemberIndex += 1;
    });
  });

  const [activeTeamMember, setActiveTeamMember] = useState(null);

  const handleModalNavigation = direction => {
    const findMember = index => {
      console.log(index);
      setActiveTeamMember(members.find(member => member.id === index));
    };

    if (direction === "right" && activeTeamMember.id !== members.length - 1)
      return findMember(activeTeamMember.id + 1);
    if (direction === "left" && activeTeamMember.id !== 0)
      return findMember(activeTeamMember.id - 1);
  };

  const openTeamMemberModal = index => setActiveTeamMember(index);

  return (
    <TeamSection className="container">
      <AnchorLink id="team" />
      <H3>{ teamTitle.title }</H3>

      {teamMembers.map((section, index) => (
        <TeamList
          key={index}
          teamMembers={section.team_member}
          openTeamMemberModal={openTeamMemberModal}>
          {section.team_section}
        </TeamList>
      ))}

      {activeTeamMember !== null && (
        <TeamModal
          teamMember={activeTeamMember}
          handleModalNavigation={handleModalNavigation}
          setActiveTeamMember={setActiveTeamMember}
        />
      )}
    </TeamSection>
  );
});

const TeamSection = styled.section`
  margin: 5rem auto;
  text-align: center;

  ul {
    margin-bottom: 2rem;
  }
`;

export default Team;
