import React, { useState } from "react";
import { H4 } from "../../Styles/Typography";
import Accordion from "./Accordion";
import styled from "styled-components";
import Diagram from "./Diagram.js";

const Features = React.memo(({ features }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <FeaturesSection className="container">
      <H4>{features.headline}</H4>
      <div className="row">
        <DiagramContainer className="col-xs-12 col-md-6">
          <Diagram activeItem={activeItem} setActiveItem={setActiveItem} />
        </DiagramContainer>
        <div className="col-xs-12 col-md-6">
          <Accordion
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            content={ features.items}
          />
        </div>
      </div>
    </FeaturesSection>
  );
});

const FeaturesSection = styled.section`
  margin: 4rem auto;
`;

const DiagramContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export default Features;
