import React from "react";
import AnchorLink from "../../AnchorLink";
import { H3 } from "../../Styles/Typography";
import { Button, ButtonRightWrapper } from "../../Styles/Buttons";
import { Link } from "gatsby";
import NewsGrid from "./NewsGrid";
import styled from "styled-components";

const News = ({ newsContent, newsIntro }) => {
  return (
    <NewsSectionWrapper className="container-fluid">
      <AnchorLink id="news" />
      <NewsIntro>
        <div className="container">
          <div className="row middle-md">
            <div className="col-xs-12 col-md-8">
              <H3>{ newsIntro.title }</H3>
              <p dangerouslySetInnerHTML={{ __html: newsIntro.paragraph }} />
            </div>
            <ButtonRightWrapper className="col-xs-12 col-md-4">
              <Button as={Link} to={"/latest-news"}>
                Show All
              </Button>
            </ButtonRightWrapper>
          </div>
        </div>
      </NewsIntro>
      <NewsGrid newsContent={newsContent} />
    </NewsSectionWrapper>
  );
};

const NewsSectionWrapper = styled.section`
  margin: 2rem auto;

  @media (min-width: ${props => props.theme.md}) {
    margin: 4rem auto;
    padding: 2rem 0;
  }
`;

const NewsIntro = styled.div`
  padding: 3rem 0 2rem;
  background-color: ${props => props.theme.mainLighter2};

  p {
    font-size: 20px;
  }

  a {
    margin: 1.5rem 0 0;
  }
  
  @media (min-width: ${props => props.theme.md}) {
      padding: 3rem 0;

  }
`;

export default News;
