import React from "react";
import { H1 } from "../../Styles/Typography";
import { Button, ButtonOutlineWhite } from "../../Styles/Buttons";
import styled from "styled-components";
import { Link } from "gatsby";

const Hero = ({ hasScrolled, image, heroContent }) => {
  return (
    <HeroSection
      className={`container-fluid ${hasScrolled ? "hasScrolled" : ""}`}>
      {image.localFile && (
        <HeroImage
          style={{
            backgroundImage: `url(${
              image.localFile.childImageSharp.fluid.src
            })`,
          }}
        />
      )}
      <div className="container">
        <HeroBody>
          <LogoIcon>
            <svg viewBox="0 0 248 248">
              <path d="M232.8 127.7c-2 58.3-50 105.1-108.8 105.1-60.6 0-108.4-51.5-108.9-108.9C14.7 73.8 50.3 28 101.9 15l1.5-13.6c-5.3 1.3-10.6 2.7-15.9 4C36.9 21.1 0 68.3 0 124c0 68.4 55.6 124 124 124 67.1 0 121.9-53.6 123.9-120.3h-15.1z" />
              <path d="M248 122.3l-.4-5.5h.2c-.2-3.2-.5-6.4-.9-9.6v-.1H131.6V15.4c43.8 3 80.5 32 94.8 71.6 4.5-2.4 8.9-4.9 13.4-7.4C221.8 33.1 176.7 0 124 0c-2.4 0-4.8.1-7.2.2l-.4-.2v220.3h15.1v-98.1h74.2l42.3.1z" />
            </svg>
          </LogoIcon>
          <H1>{heroContent.headline}</H1>
          <div dangerouslySetInnerHTML={{ __html: heroContent.paragraph }} />
        </HeroBody>
        <HeroButtons>
          <Button to={"/apply-for-funding"} as={Link}>
            Apply for funding
          </Button>
          <ButtonOutlineWhite to={"#invest"} as={Link}>
            Apply for investing
          </ButtonOutlineWhite>
        </HeroButtons>
      </div>
    </HeroSection>
  );
};

const HeroSection = styled.section`
  min-height: ${props => `calc(100vh - ${props.theme.headerHeight})`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  position: relative;
  padding-top: 3vh;
  transition: padding 0.25s ease-out;
  z-index: 1;

  &.hasScrolled {
    padding-top: 5vh;

    @media (min-width: 450px) {
      padding-top: 6vh;    
    }

    @media (min-width: ${props => props.theme.sm}) {
      padding-top: 8vh;    
    }
    
    @media (min-width: ${props => props.theme.md}) {
      padding-top: 20vh;
    }
  }

  h1 {
    margin: 0 auto 0.5rem;
    font-size: 15px;
    line-height: 1.2;
    
    @media (min-width: 360px) {
      font-size: 16px;
      line-height: 1.3;
    }
    
    @media (min-width: 400px) {
      font-size: 18px;
    }

    @media (min-width: ${props => props.theme.sm}) {
      font-size: 26px;
      line-height: 1.4;
    }

    @media (min-width: ${props => props.theme.md}) {
      margin: 0 auto 1rem;
      font-size: 38px;
    }

    @media (min-width: ${props => props.theme.lg}) {
      font-size: 45px;
      line-height: 1.2;
    }
  }

  .container {
    z-index: 1;
    position: relative;
  }
`;

const HeroButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    margin: 0.25rem;
  }

  @media (min-width: ${props => props.theme.sm}) {
    margin: 0.5rem;
  }

  @media (min-width: ${props => props.theme.md}) {
    flex-direction: row;

    a {
      margin: 1rem;
    }
  }
`;

const HeroBody = styled.div`
  max-width: 940px;
  margin: 0 auto;

  p {
    padding: 0.5rem 0;
    font-size: 13px;
    line-height: 1.2;

    @media (min-width: 360px) {
      font-size: 14px;
      line-height: 1.3;
    }

    @media (min-width: 450px) {
      font-size: 15px;
    }

    @media (min-width: ${props => props.theme.sm}) {
      padding: 0.5rem 2rem;
      font-size: 16px;
      line-height: 1.5;
    }

    @media (min-width: ${props => props.theme.md}) {
      padding: 0.5rem 4rem;
    }

    @media (min-width: ${props => props.theme.lg}) {
      font-size: 18px;
      line-height: 1.6;
    }
  }
`;

const HeroImage = styled.div`
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  top: -80px;
  left: 0;
  z-index: 0;
  position: absolute;
  transition: all 0.25s ease-out;
  
  @media (min-width: ${props => props.theme.md}) {
    //height: 100vh;

    ${HeroSection}.hasScrolled & {
      //height: calc(100vh);
      height: 115%;
      min-height: auto;
      width: calc(100% - 6rem);
      left: 3rem;
      top: 0;
    }
  }
  
  @media (min-width: ${ props => props.theme.lg }) {
    ${ HeroSection }.hasScrolled & {
      height: 110%;
    }
  }
`;

const LogoIcon = styled.div`
  width: 40px;
  margin: 0 auto 0.75rem;
  color: white;
  fill: white;
  
  @media (min-width: 360px) {
      width: 50px;
  }
  
  @media (min-width: 450px) {
    width: 60px;
  }

  @media (min-width: ${props => props.theme.sm}) {
    width: 70px;
  }
  
  @media (min-width: ${props => props.theme.md}) {
    width: 90px;
  }
`;

export default Hero;
