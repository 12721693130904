import React from "react";
import styled from "styled-components";
import { Icon } from "../../Icons/Icons";

const Quote = ({quoteContent}) => {
  return (
    <QuoteWrapper className="container">
      <QuoteContent>
        <QuoteIcon>
          <Icon
            name="quotes"
            iconHeight="50"
            iconWidth="50"
            viewBoxWidth="512"
          />
        </QuoteIcon>
        <div dangerouslySetInnerHTML={{ __html: quoteContent.quote}} />
        <QuoteIcon>
          <Icon
            name="quotes"
            iconHeight="50"
            iconWidth="50"
            viewBoxWidth="512"
          />
        </QuoteIcon>
      </QuoteContent>
      <strong>{quoteContent.author}</strong>
    </QuoteWrapper>
  );
};

const QuoteWrapper = styled.section`
  text-align: center;
  margin: 4rem auto 6rem;
  max-width: 840px;

  p {
    font-weight: 300;
    text-align: justify;
    
    letter-spacing: -0.5px;
    line-height: 1.4;
    opacity: 0.8;
  }

  strong {
    font-weight: 600;
    text-align: center;
    margin-top: 0.5rem;
    //display: block;
  }
  
  @media (min-width: ${props => props.theme.md}) {
    margin: 6rem auto 8rem;
  }

  @media (min-width: ${props => props.theme.lg}) {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    
    p, strong {
    font-size: 22px;
        line-height: 1.7;
    }

    h2 {
      font-size: 26px;
    }
  }
`;

const QuoteIcon = styled.div`
  color: ${props => props.theme.main};
  position: absolute;
  left: -75px;
  top: -20px;
  display: none;
  
  @media (min-width: ${props => props.theme.lg}) {
    display: block;
  }
`;

const QuoteContent = styled.div`
  position: relative;

  ${QuoteIcon}:last-of-type {
    left: auto;
    top: auto;
    right: -75px;
    bottom: -10px;

    .icon-wrapper {
      transform: rotate(180deg);
    }
  }
`;

export default Quote;
