import React from "react";
import MainLayout from "../components/Layout/MainLayout";
import Team from "../components/Layout/Team/Team";
import Hero from "../components/Layout/Hero/Hero";
import Investing from "../components/Layout/Investing/Investing";
import Funding from "../components/Layout/Funding/Funding";
import PartnersSection from "../components/Layout/Partners/PartnersSection";
import News from "../components/Layout/News/News";
import About from "../components/Layout/About/About";
import Quote from "../components/Layout/Quote/Quote";
import Features from "../components/Layout/Features/Features";
import { graphql } from "gatsby";
import SectionIndicator from "../components/SectionIndicator";
import Waypoint from "react-waypoint";

export default class Index extends React.Component {
  state = {
    hasScrolled: false,
  };

  onScroll = () => {

    window.pageYOffset > 5
      ? this.setState(() => ({
        hasScrolled: true
      }))
      : this.setState(() => ({
        hasScrolled: false
      }));
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  handleEnter = (section) => {
    this.setState({ currentSection: section })
  };

  render() {
    const {
      featured_media,
      acf
    } = this.props.data.allWordpressPage.edges[0].node;

    const {
      banner_articles,
      hero,
      latest_news,
      quote,
      about_us,
      features,
      partners,
      team,
      team_title,
      partners_title,
      latest_news_intro,
      apply_for_funding,
      apply_for_investing
    } = acf;

    return (
      <MainLayout location={ this.props.location }>
        <Waypoint onEnter={ () => this.handleEnter("") } onExit={ () => this.handleEnter("") } fireOnRapidScroll={true}>
          <div>
            <Hero hasScrolled={ this.state.hasScrolled } heroContent={ hero[0] } image={ featured_media } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("") } onExit={ () => this.handleEnter("") } fireOnRapidScroll={true}>
          <div>
            <Quote quoteContent={ quote[0] } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("about") } bottomOffset={80} topOffset={ -100 } fireOnRapidScroll={true}>
          <div>
            <About bannerArticles={ banner_articles } aboutContent={ about_us[0] } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("team") } bottomOffset={80} topOffset={ -100 } fireOnRapidScroll={true}>
          <div>
            <Team teamContent={ team } teamTitle={ team_title[0] } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("investing") } bottomOffset={80} topOffset={ 150 } fireOnRapidScroll={true}>
          <div>
            <Features features={ features[0] } />
            <Investing investingContent={ apply_for_investing[0] } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("funding") } bottomOffset={0} topOffset={ 0 } fireOnRapidScroll={true}>
          <div>
            <Funding fundingContent={ apply_for_funding[0] } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("partners") } bottomOffset={-50} topOffset={ 100 } fireOnRapidScroll={true}>
          <div>
            <PartnersSection partners={ partners } partnersTitle={ partners_title[0] }/>
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("news") } bottomOffset={400} topOffset={ 0 } fireOnRapidScroll={true}>
          <div>
            <News newsContent={ latest_news } newsIntro={ latest_news_intro[0] } />
          </div>
        </Waypoint>
        <Waypoint onEnter={ () => this.handleEnter("") } bottomOffset={ 50 } fireOnRapidScroll={ true }>
          <div />
        </Waypoint>
        <SectionIndicator currentSection={this.state.currentSection} />
      </MainLayout>
    );
  }
}

export const IndexQuery = graphql`
  query IndexQuery {
    allWordpressPage(filter: { slug: { regex: "/home/" } }) {
      edges {
        node {
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          acf {
            hero {
              headline
              paragraph
            }
            quote {
              quote
              author
            }
            about_us {
              title
              paragraph
              list {
                item
              }
            }
            features {
              headline
              items {
                title
                content
                icon
              }
            }
            team {
              team_section
              team_member {
                name
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                role
                profile
              }
            }
            partners {
              url
              name
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            banner_articles {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              url
              headline
            }
            latest_news {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              excerpt
              headline
              url
              date
            }
            team_title {
              title
            }
            partners_title {
              title
            }
            latest_news_intro {
              title
              paragraph
            }
            apply_for_funding {
              title
              paragraph
            }
            apply_for_investing {
              title
            }
          }
        }
      }
    }
  }
`;
