import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "../../Icons/Icons";

const Accordion = ({content, activeItem, setActiveItem}) => {
  return (
    <AccordionWrapper>
      {content.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            className={activeItem === index ? "open" : ""}>
            <AccordionItemTitle onClick={() => setActiveItem(index)}>
              <ItemIcon>
                <Icon name={item.icon} iconHeight={25} iconWidth={25} />
              </ItemIcon>
              <h3>{item.title}</h3>
              <IconWrapper>
                <Icon
                  name={activeItem === index ? "minus" : "plus"}
                  iconHeight={11}
                  iconWidth={11}
                  viewBoxHeight={512}
                  viewBoxWidth={512}
                />
              </IconWrapper>
            </AccordionItemTitle>
            <AccordionItemBody>
              <div>
                <AnswerBody>{item.content}</AnswerBody>
              </div>
            </AccordionItemBody>
          </AccordionItem>
        );
      })}
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled.div`
  margin-bottom: 3rem;

  h3 {
    font-size: 28px;
  }
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #eceeef;
`;

const AnswerBody = styled.div`
  margin: 0.5rem 0 1.5rem 0;
  padding-right: 1.5rem;
`;

const ItemIcon = styled.div`
  margin-right: 20px;
  color: ${props => props.theme.main};
  padding: 9px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.main};

  .icon-wrapper {
    width: 25px;
    height: 25px;
  }
`;

const AccordionItemTitle = styled.button`
  color: ${props => props.theme.main};
  cursor: pointer;
  padding: 1.5rem 0;
  width: 100%;
  text-align: left;
  border: none;
  transition: background-color 0.15s ease-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: 0;

  h3 {
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${props => props.theme.text};
    font-weight: 600;
    flex-grow: 1;
  }

  //&:hover {
  //  background-color: #f6f6f6;
  //}

  ${AccordionItem}.open & {
    //border-bottom: 1px solid #eceeef;
    ${ItemIcon} {
      background: ${props => props.theme.main};
      color: white;
    }
  }
`;

const AccordionItemBody = styled.div`
  display: none;
  opacity: 0;
  animation: fadein 0.35s ease-out;
  text-align: justify;
  background-color: white;
  color: ${props => props.theme.text};

  h4 {
    color: ${props => props.theme.text};
  }

  ul {
    list-style: none;

    li {
      margin-left: 1rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 5px;
        width: 5px;
        top: 10px;
        left: -1rem;
        background-color: ${props => props.theme.main};
        border-radius: 50%;
      }
    }
  }

  p,
  ul,
  ol {
    padding: 0.25rem 0;
    font-weight: 300;
  }

  ol {
    li {
      margin-left: 1.5rem;
      position: relative;
      counter-increment: decimal-counter;

      &::before {
        content: counter(decimal-counter) ". ";
        color: ${props => props.theme.main};
        left: -1.5rem;
        position: absolute;
      }
    }
  }

  ${AccordionItem}.open & {
    opacity: 1;
    display: block;
    animation: fadein 0.35s ease-out;
  }
`;

const IconWrapper = styled.div`
  height: 28px;
  width: 28px;
  color: ${props => props.theme.main};
  border: 1px solid ${props => props.theme.main};
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;

  .icon-wrapper {
    height: 27px;
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  ${AccordionItem}.open & {
    opacity: 1;
  }
`;

export default Accordion;
