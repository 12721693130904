import React, { useState } from "react";
import styled from "styled-components";
import fallbackImage from "../../../../static/finova-placeholder.jpg";

const BannerSlider = React.memo(({ bannerArticles }) => {
  const [activeItem, setActiveItem] = useState(1);

  return (
    <BannerSliderWrapper>
      <BannerSliderContainer>
        <SliderContainer>
          <Slider
            activeItem={activeItem}
            style={{ height: `${145 * bannerArticles.length - 15}px` }}>
            {bannerArticles.map((item, index) => (
              <SliderItem
                key={index}
                className={index === activeItem ? "isActive" : ""}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <ItemMedia>
                    <BgImg
                      style={{
                        backgroundImage: `url(${
                          item.image && item.image.localFile
                            ? item.image.localFile.childImageSharp.fluid.src
                            : fallbackImage
                        })`,
                      }}
                    />
                  </ItemMedia>
                  <ItemBody>
                    <p>{item.headline}</p>
                  </ItemBody>
                </a>
              </SliderItem>
            ))}
          </Slider>
        </SliderContainer>
      </BannerSliderContainer>
      <SliderNavigation>
        {bannerArticles.map((item, index) => (
          <li
            key={index}
            onClick={() => setActiveItem(index)}
            className={index === activeItem ? "isActive" : ""}
          />
        ))}
      </SliderNavigation>
    </BannerSliderWrapper>
  );
});

const BannerSliderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const BannerSliderContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const SliderContainer = styled.div`
  overflow: hidden;
  height: 330px;

  @media (min-width: ${props => props.theme.md}) {
    height: 420px;
  }
`;

const Slider = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 330px;
  transform: ${props => `translateY(${props.activeItem * -115 + 115}px)`};
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-right: 3rem;
  overflow: hidden;

  @media (min-width: ${props => props.theme.md}) {
    height: 420px;
    transform: ${props => `translateY(${props.activeItem * -145 + 145}px)`};
    padding-left: 2rem;
    padding-right: 4rem;
  }
`;

const SliderItem = styled.li`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.mainLighter};
  display: block;
  width: 100%;
  height: 100px;
  opacity: 0.7;
  transition: opacity 0.25s ease-out, border-color 0.25s ease-out;
  margin-bottom: 15px;
  cursor: pointer;

  a {
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    color: ${props => props.theme.text};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.isActive {
    opacity: 1;
    border-color: ${props => props.theme.main};
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.mainAccent};
  }

  @media (min-width: ${props => props.theme.md}) {
    height: 130px;

    a {
      padding: 0 1.5rem;
    }
  }
`;

const ItemBody = styled.div`
  flex-grow: 1;

  p {
    font-size: 12px;
    line-height: 1.3;
    text-align: justify;
    
    
    @media (min-width: ${ props => props.theme.md }) {
      font-size: 13px;
    }

    @media (min-width: ${props => props.theme.md}) {
      font-size: 14px;
      line-height: 1.4;
    }
  }
`;

const SliderNavigation = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  li {
    position: relative;
    overflow: hidden;
    height: 30px;
    width: 30px;
    margin-bottom: 0.25rem;
    cursor: pointer;
    opacity: 0.3;

    &::after {
      content: "";
      position: absolute;
      top: 6px;
      left: 6px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      border: 1px solid ${props => props.theme.main};
      transition: border-color 0.25s ease-out, background-color 0.25s ease-out;

      &:hover,
      &:focus {
        border-color: ${props => props.theme.mainAccent};
      }
    }

    &.isActive {
      opacity: 1;

      &::after {
        border-color: ${props => props.theme.main};
        background-color: ${props => props.theme.main};
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ${props => props.theme.md}) {
      margin-bottom: 0.75rem;
    }
  }
`;

const ItemMedia = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 0.75rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  @media (min-width: ${props => props.theme.md}) {
    height: 80px;
    width: 80px;
    margin-right: 1rem;
  }
`;

const BgImg = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

export default BannerSlider;
