import React, { useState } from "react";
import styled from "styled-components";

const Partners = ({ partners }) => {
  const initialValue =
    Math.floor(partners.length / 2) > 0
      ? Math.floor(partners.length / 2) - 1
      : 0;
  const [activeItem, setActiveItem] = useState(initialValue);

  return (
    <PartnersWrapper>
      <SliderContainer>
        <Slider activeItem={activeItem}>
          {partners.map((item, index) => (
            <SliderItem
              key={index}
              className={`${index === activeItem ? "isActive" : ""} logo-${
                item.name
              }`}
              onClick={() => setActiveItem(index)}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <div
                  style={{
                    backgroundImage: `url(${
                      item.image.localFile.childImageSharp.fluid.src
                    })`,
                  }}
                />
              </a>
            </SliderItem>
          ))}
        </Slider>
      </SliderContainer>
      <SliderDots>
        {partners.map((item, index) => (
          <SliderDot
            key={index}
            onClick={() => setActiveItem(index)}
            className={index === activeItem ? "isActive" : ""}
          />
        ))}
      </SliderDots>
    </PartnersWrapper>
  );
};

const PartnersWrapper = styled.div`
  overflow: hidden;

  @media (min-width: ${props => props.theme.lg}) {
    position: relative;
  }
`;

const SliderContainer = styled.div`
  transform: translateX(25%);

  @media (min-width: ${props => props.theme.md}) {
    transform: translateX(33.33%);
  }

  @media (min-width: ${props => props.theme.lg}) {
    transform: translateX(40%);
  }
`;

const Slider = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  will-change: transform;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: ${props => `translateX(${props.activeItem * -50}%)`};

  @media (min-width: ${props => props.theme.md}) {
    transform: ${props => `translateX(${props.activeItem * -33.33}%)`};
  }

  @media (min-width: ${props => props.theme.lg}) {
    transform: ${props => `translateX(${props.activeItem * -20}%)`};
  }
`;

const SliderItem = styled.li`
  padding: 1.25rem;
  overflow: hidden;
  filter: grayscale(1);
  transition: filter 0.5s ease-out, opacity 0.1s ease-out;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  min-width: 50%;

  @media (min-width: ${props => props.theme.md}) {
    min-width: 33.33%;
    padding: 2rem;
  }

  @media (min-width: ${props => props.theme.lg}) {
    min-width: 20%;
  }

  &.isActive {
    filter: grayscale(0);
    opacity: 1;
  }

  &.logo-Altshuler,
  &.logo-Shibolet {
    div {
      min-height: 90px;
    }
  }

  div {
    background-size: contain;
    min-height: 50px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  :hover {
    filter: grayscale(0);
  }
`;

const SliderDots = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  
  @media (min-width: ${props => props.theme.lg}) {
    margin-bottom: 0;
  }
`;

const SliderDot = styled.button`
  line-height: 1;
  margin-right: 0.5rem;
  opacity: 0.3;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.main};
  }

  &.isActive {
    opacity: 1;

    &::after {
      border-color: ${props => props.theme.main};
      background-color: ${props => props.theme.main};
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default Partners;
