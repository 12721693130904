import React from "react";
import { ButtonLink } from "../../Styles/Buttons";
import styled from "styled-components";
import { Icon } from "../../Icons/Icons";
import fallbackImage from "../../../../static/finova-placeholder.jpg";

const NewsGrid = React.memo(({ newsContent }) => {
  const featuredArticle = newsContent[0];
  const articles = newsContent.slice(1);

  return (
    <NewsGridWrapper className="container">
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <NewsItemMain>
            <NewsItemMedia
              href={featuredArticle.url}
              target="_blank"
              rel="noopener noreferrer">
              <BgImg
                style={{
                  backgroundImage: `url(${
                    featuredArticle.image && featuredArticle.image.localFile
                      ? featuredArticle.image.localFile.childImageSharp.fluid
                          .src
                      : fallbackImage
                  })`,
                }}
              />
              <MediaLink>
                <Icon name="link" iconHeight={40} iconWidth={40} />
              </MediaLink>
            </NewsItemMedia>
            <NewsItemBody>
              <small>{featuredArticle.date}</small>
              <h3>{featuredArticle.headline}</h3>
              <p>{featuredArticle.excerpt}</p>
              <ButtonLink
                href={featuredArticle.url}
                target="_blank"
                rel="noopener noreferrer">
                Read more
              </ButtonLink>
            </NewsItemBody>
          </NewsItemMain>
        </div>
        <NewsItems className="col-xs-12 col-md-6">
          {articles &&
            articles.map((item, index) => (
              <NewsItem key={index}>
                <NewsItemMedia
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer">
                  <BgImg
                    style={{
                      backgroundImage: `url(${
                        item.image && item.image.localFile
                          ? item.image.localFile.childImageSharp.fluid.src
                          : fallbackImage
                      })`,
                    }}
                  />
                  <MediaLink>
                    <Icon name="link" iconHeight={40} iconWidth={40} />
                  </MediaLink>
                </NewsItemMedia>
                <NewsItemBody>
                  <small>{item.date}</small>
                  <h3>{item.headline}</h3>
                  <ButtonLink
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    Read more
                  </ButtonLink>
                </NewsItemBody>
              </NewsItem>
            ))}
        </NewsItems>
      </div>
    </NewsGridWrapper>
  );
});

const NewsGridWrapper = styled.div`
  margin: 2rem auto;

  @media (min-width: ${props => props.theme.md}) {
    margin: 4rem auto;
  }
`;

const BgImg = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;

const MediaLink = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  top: 0;
  left: 0;
  color: white;
  z-index: 2;
`;

const NewsItemMedia = styled.a`
  height: 200px;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 4px;
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.mainAccent};
    z-index: 1;
    opacity: 0;
    transition: opacity 0.65s ease-out;
  }

  :hover {
    &::after {
      opacity: 0.4;
    }

    ${MediaLink} {
      opacity: 1;
    }
  }
  
  @media (min-width: ${props => props.theme.md}) {
    height: 380px;
  }
`;

const NewsItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const NewsItemBody = styled.div`
  overflow: hidden;

  h3 {
    font-size: 17px;
    color: #0f2522;
    letter-spacing: 0;
    line-height: 1.5;
  }

  small {
    opacity: 0.7;
    font-size: 14px;
    color: #0a0724;
    letter-spacing: 0;
    padding-bottom: 0.25rem;
  }

  p {
    font-size: 15px;
    line-height: 1.6;
  }

  a {
    font-size: 13px;
    color: ${props => props.theme.mainAccent};
    letter-spacing: 2px;
    text-align: center;
    line-height: 1;
  }

  @media (min-width: ${props => props.theme.md}) {
    h3 {
      font-size: 20px;
      line-height: 1.6;
    }

    p {
      font-size: 17px;
    }
  }
`;

const NewsItem = styled.div`
  width: calc(100%);
  margin-bottom: 1rem;

  ${NewsItemMedia} {
    height: 140px;
    
    @media (min-width: ${props => props.theme.md}) {
    height: 170px;
      
    }
  }

  ${NewsItemBody} {
    h3 {
      min-height: 62px;
      line-height: 1.4;
    }
  }

  @media (min-width: ${props => props.theme.sm}) {
    width: calc(50% - 0.5rem);

    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: 1rem;
    }
  }
`;

const NewsItemMain = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${NewsItemBody} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;

    a {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      padding-bottom: 5px;
      margin-bottom: 1.5rem;
      
      @media (min-width: ${props => props.theme.md}) {
        margin-bottom: 0;
      }
    }
  }
`;

export default NewsGrid;
