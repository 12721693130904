import React from "react";
import styled from "styled-components";

const SectionIndicator = ({ currentSection }) => {
  return (
    <>
      { currentSection && (
        <SectionIndicatorWrapper className={ currentSection.length > 0 ? "" : "isHidden" }>
          { currentSection }
        </SectionIndicatorWrapper>
      )}
    </>
  );
};

const SectionIndicatorWrapper = styled.div`
  display: none;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  transform: rotate(-90deg) translateZ(0);
  transform-origin: 18px 50%;
  height: 40px;
  padding-top: 12px;
  width: 110px;
  text-align: center;
  transition: opacity 0.25s ease-out;
  color: #999ca7;
  
  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: calc(50% - 20px);
    width: 40px;
    height: 5px;
    background-color: ${ props => props.theme.mainAccent };
  }
  
  &.isHidden {
    opacity: 0;
  }
  
  @media (min-width: ${ props => props.theme.lg }) {
    display: block;
  }
`;

export default SectionIndicator;