import React, { useState } from "react";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import { Button, ButtonRightWrapper } from "../Styles/Buttons";
import styled from "styled-components";
import FormCheckbox from "./FormCheckbox";
import countryList from "./countries";
import { ButtonIcon, SpinnerIcon } from "../Icons/Icons";

const InvestingForm = () => {
  const defaultValues = {
    honeypot: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "select",
    companyName: "",
    accreditedInvestor: false,
    phoneCode: ""
  };

  const [values, setValues] = useState(defaultValues);
  const [isFormSuccess, setFormState] = useState(false);
  const [isFormLoading, setFormLoading] = useState(false);

  const handleOnChange = e => {
    const name = e.target.name;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setValues({
      ...values,
      [name]: value
    });

    if (name === "country") {
      let countrySelected = countryList.find(
        country => country.name === value
      );

      setValues({
        ...values,
        country: countrySelected.name,
        phoneCode:
          countrySelected !== undefined ? countrySelected.phone_code : ""
      });
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (values.country === "select") return;
    if (values.honeypot !== "") return;
    setFormLoading(true);
    fetch(
      `https://exzkcvjh9a.execute-api.us-east-1.amazonaws.com/dev/finova-capital-investing-form`,
      {
        method: "POST",
        body: JSON.stringify({
          ses_address: "contact@finova-capital.com",
          send_to: "contact@finova-capital.com",
          subject: "Finova Capital | Investing Application",
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneCode + values.phoneNumber,
          country: values.country,
          companyName: values.companyName,
          accreditedInvestor: values.accreditedInvestor ? "Yes" : "No"
        }),
        headers: new Headers({
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8"
        })
      }
    )
      .then(response => {
        setFormState(true);
        setFormLoading(false);
        setTimeout(() => setFormState(false), 4000);
        return response.json();
      })
      .catch(err => {
        setFormState(false);
        setFormLoading(false);
        console.log(err);
      });
  };

  return (
    <FormWrapper className="container">
      <form onSubmit={ onSubmit }>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <input type="input" name="honeypot" readOnly={ true } value="" style={ { display: "none" } } tabIndex="-1"
                   autoComplete="off" />
            <FormInput required label="First Name" type="text" name="firstName" value={ values.firstName }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Last Name" type="text" name="lastName" value={ values.lastName }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormSelect required label="Country of Residency" name="country" value={ values.country }
                        options={ countryList } onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Company Name" type="text" name="companyName" value={ values.companyName }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Email" type="email" name="email" value={ values.email }
                       onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormInput required label="Phone Number" type="tel" name="phoneNumber" prefix={ values.phoneCode }
                       value={ values.phoneNumber } onChange={ handleOnChange } />
          </div>
          <div className="col-xs-12 col-md-6">
            <FormCheckbox label="Accredited Investor under your jurisdiction?" type="checkbox" name="accreditedInvestor"
                          id="accreditedInvestor" value={ values.accreditedInvestor } onChange={ handleOnChange } />
          </div>
          <ButtonRightWrapper className="col-xs-12 col-md-6">
            <Button as="button" type="submit">
              { !isFormLoading &&
              !isFormSuccess && <span>Send application</span> }
              { isFormLoading && (
                <>
                  Sending <ButtonIcon name="loading" />
                </>
              ) }
              { isFormSuccess &&
              !isFormLoading && (
                <>
                  Successfully sent <ButtonIcon name="check" />
                </>
              ) }
            </Button>
          </ButtonRightWrapper>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p>
              You can also contact us at: <a>contact@Finova-Capital.com</a>
            </p>
          </div>
        </div>
      </form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  max-width: 860px;
  margin: 0 auto;
`;

export default InvestingForm;
