import React from "react";
import styled from "styled-components";

const Diagram = ({ activeItem, setActiveItem }) => {
  return (
    <DiagramWrapper>
      <DiagramContent>
        <svg viewBox="0 0 457.9 457.9">
          <circle cx="225.5" cy="239.8" r="177" fill="#f9fbfe" />
          <g>
            <g>
              <path
                d="M90.7 238.8l-46.6-24.2M387 238.5l-59.1 30M54 343.9l92.5-2.4 42-16 34.6 71 45.4-56 72.2 44.4 19-60.1M424.9 268.2L387 238.5l-19.4-105-63-14"
                className="st1" />
              <path
                d="M367.6 133.5l-53.7 75.7 66.7 27M268.5 143.1l-46.4-81.6-33.6 37-70-57.4L140 149.5l-62.3 5.1 13 84.2L54 341.9l85.5-77.4-61.8-109.9M268.5 143.1l36.1-23.6-82.5-58M146.5 341.5l-7 62.9M139.5 404.4l49-78.9M77.7 154.6l40.8-113.5M223.1 396.5l117.6-11.6M387 238.5L359.9 325l-32-56.5"
                className="st1" />
            </g>
            <g>
              <path d="M268.5 340.5l-46-101-76 102M118.5 41.1l107 197.7-144.3-.3M223.2 238.5h162.5" className="st2" />
              <path
                d="M222.5 399.5v-160L41.6 349.9M367.5 133.5l-145 106 137.2 85.3M66.5 149.5l156 90v-193M222.5 239.5l-83-90M188.5 325.5l34-86"
                className="st2" />
              <circle cx="139.5" cy="149.5" r="16.5" className="st3" />
              <path d="M340.7 384.9L222.5 239.5M221.4 242.5l47.1-99.4" className="st2" />
              <circle cx="268.5" cy="143.1" r="16.5" className="st3" />
              <circle cx="387" cy="238.5" r="16.5" className="st3" />
              <circle cx="146.5" cy="341.5" r="16.5" className="st3" />
              <circle cx="90.7" cy="238.8" r="16.5" className="st3" />
              <circle cx="268.5" cy="340.5" r="16.5" className="st3" />
            </g>
            {/* Button icons */ }
            <g>
              { [
                {
                  circleCX: "222.1",
                  circleCY: "61.5",
                  iconPath:
                    "M237.5 63.4h-14.3l9.5 9.5c.2.2.4.3.7.3.2 0 .5-.1.6-.2 2.3-2.2 3.9-5.2 4.4-8.5.2-.6-.3-1.1-.9-1.1zm-4.1 7.4l-5.5-5.5h8.5c-.5 2.1-1.5 4-3 5.5zm-14.2-7.4V49.1c0-.5-.4-1-.9-1h-.1c-7.2 1-12.7 7.3-12.4 14.8.3 7.7 6.8 13.9 14.4 13.9h.2c3 0 5.8-1 8.1-2.7.5-.3.5-1 .1-1.4l-9.4-9.3zm1.2 11.5h-.2c-6.6 0-12.3-5.5-12.5-12.1-.2-6 3.9-11.3 9.6-12.6v13.9l.6.6 8.5 8.5c-1.8 1.1-3.9 1.7-6 1.7zm16.2-15.4c-.5-7.2-6.2-12.9-13.4-13.4h-.1c-.5 0-.9.4-.9 1v13.5h13.5c.5-.1.9-.6.9-1.1zm-12.5-.9V48.1c5.4.8 9.6 5.1 10.5 10.5h-10.5z",
                },
                {
                  circleCX: "367.5",
                  circleCY: "133.5",
                  iconPath:
                    "M367.5 123.9c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6c.1-5.3-4.2-9.6-9.6-9.6zm0 17.3c-4.2 0-7.7-3.4-7.7-7.7s3.4-7.7 7.7-7.7 7.7 3.4 7.7 7.7-3.4 7.7-7.7 7.7zm0-23.4c-8.7 0-15.7 7-15.7 15.7s7 15.7 15.7 15.7 15.7-7 15.7-15.7-7-15.7-15.7-15.7zm0 29.4c-7.5 0-13.7-6.1-13.7-13.7 0-7.5 6.1-13.7 13.7-13.7s13.7 6.1 13.7 13.7c0 7.5-6.1 13.7-13.7 13.7zm0-17.2c-2 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.5-3.5-3.5-3.5z",
                },
                {
                  circleCX: "359.9",
                  circleCY: "325",
                  iconPath:
                    "M364.2 313.7c4.3 0 7.8 3.5 7.8 7.8s-3.5 7.8-7.8 7.8c-1 0-2-.2-2.9-.6L359 331h-2.6v3.5h-3.5v3.5h-5.2v-4.3l9.2-9.2c-.4-.9-.6-1.9-.6-2.9.1-4.4 3.6-7.9 7.9-7.9m0-1.7c-5.3 0-9.5 4.3-9.5 9.5 0 .8.1 1.6.3 2.4l-8.6 8.6c-.2.2-.4.6-.4.9v4.9c0 .7.6 1.3 1.3 1.3h6.1c.7 0 1.3-.6 1.3-1.3v-2.2h2.2c.7 0 1.3-.6 1.3-1.3v-2.2h1c.3 0 .7-.1.9-.4l1.7-1.7c.8.2 1.6.3 2.4.3 5.3 0 9.5-4.3 9.5-9.5 0-5-4.2-9.3-9.5-9.3zm2.6 5.9c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1-1.1-.5-1.1-1.1.5-1.1 1.1-1.1m0-1.6c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6 1.4 0 2.6-1.2 2.6-2.6 0-1.4-1.2-2.6-2.6-2.6z",
                },
                {
                  circleCX: "223.1",
                  circleCY: "396.5",
                  iconPath:
                    "M230 387.6l-5.6-5.6c-.4-.4-.8-.5-1.3-.5s-.9.2-1.3.5l-5.6 5.6c-.7.7-.7 1.9 0 2.6l5.6 5.6c.4.4.8.5 1.3.5s.9-.2 1.3-.5l5.6-5.6c.8-.7.8-1.8 0-2.6zm-6.9 7l-5.6-5.6 5.6-5.6 5.6 5.6-5.6 5.6zm-5.9 1.4c-.8-1.1-2.9-1.8-4.4-.2-.9 1-.9 2.5-.1 3.6l1.7 2.3c.4.5-.4 1.1-.8.6L210 398v-8c0-1.5-1.2-2.9-2.8-2.9-1.6 0-2.9 1.2-2.9 2.8v10c0 .6.2 1.3.6 1.8l6.3 7.8c.3.4.5 1 .6 1.6 0 .2.2.4.5.4h.9c.3 0 .5-.2.5-.5-.1-.9-.4-2-1-2.6l-6.3-7.8c-.1-.2-.2-.4-.2-.6v-10c0-1.2 1.9-1.3 1.9 0v8.8l3.8 4.5c2.2 2.6 5.7-.2 4.1-2.5l-1.8-2.5c-.3-.4-.2-1 .2-1.3.4-.3 1-.2 1.3.2l2.5 3.4c.7 1 1.1 2.2 1.1 3.4v7c0 .3.2.5.5.5h.9c.3 0 .5-.2.5-.5v-7c0-1.6-.5-3.2-1.5-4.5l-2.5-3.5zm24.6-6.1c0-1.6-1.3-2.8-2.9-2.8-1.5 0-2.8 1.4-2.8 2.9v8l-3.6 4.3c-.4.5-1.2-.1-.8-.6l1.7-2.3c.8-1.1.8-2.6-.1-3.6-1.5-1.6-3.6-.9-4.4.2l-2.5 3.4c-1 1.3-1.5 2.9-1.5 4.5v7c0 .3.2.5.5.5h.9c.3 0 .5-.2.5-.5v-7c0-1.2.4-2.4 1.1-3.4l2.5-3.4c.3-.4.9-.5 1.3-.2.5.3.5.9.2 1.3l-1.8 2.5c-1.5 2.3 2 5.1 4.1 2.5l3.8-4.5v-8.8c0-1.3 1.9-1.3 1.9 0v10c0 .2-.1.4-.2.6l-6.3 7.8c-.6.7-.9 1.7-1 2.6 0 .3.2.5.5.5h.9c.2 0 .4-.2.5-.4.1-.6.3-1.2.6-1.6l6.3-7.8c.4-.5.6-1.1.6-1.8v-9.9z",
                },
                {
                  circleCX: "54",
                  circleCY: "341.9",
                  iconPath:
                    "M71.3 340.4c-3.5-5.9-10-9.8-17.3-9.8-7.4 0-13.9 3.9-17.3 9.8-.5.9-.5 2.1 0 3 3.5 5.9 10 9.8 17.3 9.8 7.4 0 13.9-3.9 17.3-9.8.6-.9.6-2 0-3zm-9.4-.2c0 4.2-3.5 7.6-7.9 7.6s-7.9-3.4-7.9-7.6 3.5-7.6 7.9-7.6 7.9 3.4 7.9 7.6zm7.7 2.2c-3.2 5.4-9.1 8.9-15.6 8.9-6.6 0-12.5-3.5-15.6-8.9-.2-.3-.2-.7 0-1 1.8-3 4.5-5.6 7.9-7.1-1.3 1.6-2.1 3.7-2.1 5.9 0 5.3 4.4 9.6 9.9 9.6s9.9-4.3 9.9-9.6c0-2.2-.8-4.3-2.1-5.9 3.3 1.6 6.1 4.1 7.9 7.1 0 .4 0 .7-.2 1zM50 340c0-.7.2-1.3.5-1.9 0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6.6-.3 1.2-.5 1.9-.5 2.2 0 3.9 1.8 3.9 3.9s-1.8 3.9-3.9 3.9-4-1.6-4-3.8z",
                },
                {
                  circleCX: "77.7",
                  circleCY: "154.6",
                  iconPath:
                    "M77.7 163.2c-.6 0-1.2-.5-1.2-1.2v-3.7c0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2v3.7c0 .6-.5 1.2-1.2 1.2zm13-8.6v11.2c0 1.5-1.2 2.8-2.8 2.8H67.5c-1.5 0-2.8-1.2-2.8-2.8v-11.2c0-1.5 1.2-2.8 2.8-2.8h.9v-3.7c0-5.1 4.2-9.3 9.3-9.3 5.1 0 9.3 4.2 9.3 9.4v3.6h.9c1.6 0 2.8 1.2 2.8 2.8zm-20.4-2.8h14.9v-3.7c0-4.1-3.3-7.4-7.4-7.4-4.1 0-7.4 3.3-7.4 7.4v3.7zm18.6 13.9v-11.2c0-.5-.4-.9-.9-.9H67.5c-.5 0-.9.4-.9.9v11.2c0 .5.4.9.9.9h20.4c.6 0 1-.4 1-.9z",
                },
              ].map(({ circleCX, circleCY, iconPath }, index) => (
                <DiagramIcon className={ activeItem === index ? "isActive" : "" } onClick={ () => setActiveItem(index) }
                             key={ index }>
                  <circle cx={ circleCX } cy={ circleCY } r="28" className="st5" />
                  <path d={ iconPath } className="st6" />
                </DiagramIcon>
              )) }
            </g>
            <g>
              <circle cx="188.5" cy="98.5" r="7" className="st6" />
              <circle cx="139.5" cy="404.4" r="7" className="st6" />
              <circle cx="44.1" cy="214.6" r="7" className="st6" />
              <circle cx="424.9" cy="268.2" r="7" className="st6" />
              <circle cx="188.5" cy="325.5" r="7" className="st6" />
              <circle cx="327.9" cy="268.5" r="7" className="st6" />
              <circle cx="313.9" cy="209.2" r="7" className="st6" />
              <circle cx="139.5" cy="264.5" r="7" className="st6" />
              <circle cx="340.7" cy="384.9" r="7" className="st6" />
              <circle cx="118.5" cy="41.1" r="7" className="st6" />
              <circle cx="304.6" cy="119.5" r="7" className="st6" />
            </g>
          </g>
          <g>
            <circle cx="222.5" cy="239.5" r="69" className="st7" />
            <path
              d="M222.5 172.5c37 0 67 30 67 67s-30 67-67 67-67-30-67-67 30-67 67-67m0-4c-39.1 0-71 31.9-71 71s31.9 71 71 71 71-31.9 71-71-31.8-71-71-71z"
              className="st8" />
            <circle cx="222.5" cy="239.5" r="67" className="st7" />
            <g>
              <path
                d="M268.3 241.1c-.8 24.7-21.2 44.5-46.1 44.5-25.7 0-45.9-21.8-46.1-46.1-.2-21.2 14.9-40.6 36.8-46.1.2-1.9.4-3.8.6-5.8-2.2.6-4.5 1.1-6.7 1.7-21.5 6.6-37.1 26.6-37.1 50.2 0 29 23.6 52.5 52.5 52.5 28.4 0 51.7-22.7 52.5-51h-6.4z"
                className="st6" />
              <path
                d="M274.8 238.8l-.2-2.3h.1c-.1-1.4-.2-2.7-.4-4.1h-48.8v-38.9c18.5 1.3 34.1 13.6 40.2 30.3 1.9-1 3.8-2.1 5.7-3.2-7.6-19.7-26.7-33.7-49-33.7-1 0-2 0-3 .1h-.4v93.4h6.4v-41.6h49.4z"
                className="st8" />
            </g>
          </g>
        </svg>
      </DiagramContent>
    </DiagramWrapper>
  );
};

const DiagramWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  pointer-events: none;
  
  @media (min-width: ${props => props.theme.sm}) {
    height: 250px;
    width: 250px;
  }

  @media (min-width: ${props => props.theme.lg}) {
    height: 520px;
    width: 520px;
    margin: 0 auto;
    display: block;
    top: 80px;
    position: absolute;
    pointer-events: all;
  }

  svg {
    .st0 {
      fill: #f9fbfe;
    }
    .st1 {
      fill: none;
      stroke: #aed3fe;
      stroke-miterlimit: 10;
    }
    .st2 {
      fill: none;
      stroke: ${props => props.theme.main};
      stroke-width: 2;
      stroke-miterlimit: 10;
    }
    .st3 {
      fill: #ffffff;
      stroke: ${props => props.theme.main};
      stroke-width: 2;
      stroke-miterlimit: 10;
    }
    .st4 {
      fill: none;
    }
    .st5 {
      fill: #ffffff;
      stroke: ${props => props.theme.main};
      stroke-width: 4;
      stroke-miterlimit: 10;
    }
    .st6 {
      fill: ${props => props.theme.mainAccent};
    }
    .st7 {
      fill: #ffffff;
    }
    .st8 {
      fill: ${props => props.theme.main};
    }
  }
`;

const DiagramContent = styled.div`
`;

const DiagramIcon = styled.g`
  cursor: pointer;
  transform-origin: 50% 50%;
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  .st5,
  .st6 {
    transition: fill 0.25s ease-out;
  }

  &:hover,
  &.isActive {
    transform: scale(1.05);

    .st5 {
      fill: ${props => props.theme.main};
      stroke: ${props => props.theme.main};
    }

    .st6 {
      fill: #fff;
    }
  }
`;

export default Diagram;
